import AnnotationUtil from '../../../Utils/AnnotationUtil';
import BaseServiceApi from 'BaseServiceApi';
import BlockType from "../../../Constants/BlockType.js";
import ConsoleStore from "ConsoleStore";
import { DataGraphConstants } from '../../../Constants/DataGraphConstants'
import DatagraphHelper from '../../../Utils/DatagraphHelper';
import DateHelper from '../../../Utils/TimeLineHelper/Datehelper';
import DdeStatusType from '../../../Constants/DdeStatusType';
import { FinancialBlockConstants } from "../../../Constants/FinancialBlockConstants";
import GraphType from '../../../Constants/GraphType';
import { OwnerShipBlockConstants } from "../../../Constants/OwnerShipBlock";
import periodicityHelper from '../../../Utils/PeriodicityHelper';
import { PeriodicityTranslateHelper } from '../../../Utils/TranslateHelper';
import { PriceChartConstants } from '../../../Constants/PriceChartConstants';
import PriceMenuHelpers from '../../../Sagas/NavDataGraph/PriceMenu/PriceMenuHelper';
import Serializable from '../../../Serialization/Serializable';
import SettingsStore from "SettingsStore";
import SmartViewType from "../../../Constants/SmartViewType";
import SymbolType from '../../../Constants/SymbolType';
import TimeTrackingWindow from '../../../RayCustomControls/TimeTrackingWindow';
import { VolumeChartConstants } from "../../../Constants/VolumeChartConstants";
import WorkSpaceRealTimePrices from "../../../Utils/RealTimeHelper/WorkSpaceRealTimePrices";

const IntradaySourceType = BaseServiceApi.rayData["IntradaySourceType"];
const { ActionTypes } = DataGraphConstants;
const initialState = {
    TabCollection: [],
    isInitial: true,
    SelectedTabKey: undefined,
    SymbolType: SmartViewType.STOCK,
    Symbol: "",
    isOpen: false,
    isSymbolConvertedDialogOpen: false,
    isSymbolConvertedDialogAvailable: false,
    finTargetCurrencyCode: 'USD',
    isCurrencyConversion: false,
    isToolTip: false,
    processJPEG: false,
    VolPct: "",
    isRIPanelOpen: true,
    volumeRateFundamentals: "",
    isReceivedQuote: false,
    isHistoric: false,
    showEPSDate: false,
    showIPODate: false,
    SymbolInfo: {},
    CorpEventData: '',
    graphData: '',
    HsfResults: '',
    isTrading: false,
    IsNASDAQBasic: false,
    notDelayed: false,
    videoMode: false,
    pricePanelData: null,
    headerData: {},
    periodicity: GraphType.Weekly,
    nodeCount: 500,
    startXPoint: 0,
    initNodePoint: 0,
    firstNodeIndex: 0,
    isIntraday: false,
    majorPeriodicity: GraphType.Weekly,
    leftScaleWidth: 42,
    rightScaleWidth: 115,
    nodeWidth: 4,
    useCanvas: false,
    viewsSettings: {},
    priceDataAvailable: false,
    CPrice: 0,
    currPrice: 0,
    priceChangeStr: 0,
    pricePercentChangeStr: '',
    pricePercentChange: "",
    currencyStr: 'USD',
    yValue: 0,
    priceChange: 0,
    isLoading: false,
    periodicityButtons: [],
    buttonSource: {},
    disablePeriodicityButton: false,
    indicatorsHeight: 80,
    isPricePanelDataReady: false,
    endDate: new Date(),
    InitialBufferSize: 50,
    availableComponents: 1,
    dataGraphViewHeight: 0,
    periodicityType: 2,
    graphType: 2,
    selectedCountry: 0,
    periodicityValue: null,
    blockMenu: {},
    showToolTip: true,
    showNQBox: false,
    isMiniListOpen: true,
    isCommonAboutDialogOpen: false,
    commonAboutDialogUrl: '', 
    commonAboutDialogTitle: '',
    blockOutDates: [],
    isCommonStock: false
}

const GraphDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.PRICE_PANEL_RESPONSE_READY: {
            const SymbolInfo = action.pricePanelData.SymbolInfo;
            let notDelayed = true;
            let length = 0;
            let lastNodeDate = new Date();
            if (action.pricePanelData.HsfData) {
                notDelayed = action.pricePanelData.HsfData.NotDelayed;
                length = action.pricePanelData.HsfData.HSFResults ? action.pricePanelData.HsfData.HSFResults.length : 0
                lastNodeDate = DateHelper.parseJsonDate(length > 0 ? action.pricePanelData.HsfData.HSFResults[0].Date : SymbolInfo.LastTradeDate || new Date(), SymbolInfo.MomentZoneID);
            }
            const consoleSettings = SettingsStore.getConsoleSettings();
            consoleSettings.NavDatagraphSettings.symbol = SymbolInfo.Symbol;
            consoleSettings.NavDatagraphSettings.symbolTypeEnum = SymbolInfo.SymTypeEnum;
            consoleSettings.NavDatagraphSettings.msId = SymbolInfo.MsId;
            const tabDataGraphSettings = consoleSettings.NavDatagraphSettings.TabDataGraphSettings;
            const nodeCount = Math.ceil(PriceMenuHelpers.getWidth() / state.nodeWidth);
            ConsoleStore.updateNodeCount(nodeCount)
            const init = state.periodicity === GraphType.Daily || state.periodicity === GraphType.Weekly ? 1 : 0;
            const startXPoint = state.nodeWidth * ((nodeCount || length) - init);
            AnnotationUtil.setPriceChartStates({ periodicity: state.periodicity, nodeWidth: state.nodeWidth, pricePanelData: action.pricePanelData, SymbolInfo, initialNode: startXPoint });

            const gEndDate = ConsoleStore.getUserEndDate();
            let endDate = lastNodeDate;
            const IPODate = DateHelper.parseJsonDate(SymbolInfo.IPODate, SymbolInfo.MomentZoneID);
            const lastTradeDate = DateHelper.parseJsonDate(SymbolInfo.LastTradeDate || new Date(), SymbolInfo.MomentZoneID);
            
            if ((gEndDate === null || gEndDate === undefined) && tabDataGraphSettings.isHistoric && tabDataGraphSettings.userEndDate && new Date(tabDataGraphSettings.userEndDate) <= lastTradeDate && new Date(tabDataGraphSettings.userEndDate) >= IPODate) {
                endDate = new Date(tabDataGraphSettings.userEndDate);
            }
            else if (gEndDate === null || gEndDate === undefined || lastTradeDate < gEndDate) {
                endDate = lastTradeDate;
            }
            else {
                endDate = gEndDate;
            }
            let isHistoric = endDate.getTime() < lastTradeDate.getTime();
            if (SymbolInfo.SymTypeEnum === SymbolType.HISTORICSTOCK || SymbolInfo.SymTypeEnum === SymbolType.HISTORICINDEX) {
                isHistoric = true;
            }
            const openTime = DateHelper.parseJsonDate(SymbolInfo.ExchangeOpenTime, SymbolInfo.MomentZoneID);
            const closeTime = DateHelper.parseJsonDate(SymbolInfo.ExchangeCloseTime, SymbolInfo.MomentZoneID);
            const TradeOpen = new Date(lastTradeDate.getFullYear(), lastTradeDate.getMonth(), lastTradeDate.getDate(), openTime.getHours(), openTime.getMinutes());
            const TradeClose = new Date(lastTradeDate.getFullYear(), lastTradeDate.getMonth(), lastTradeDate.getDate(), closeTime.getHours(), closeTime.getMinutes());
            const { isCurrencyConversionChecked, selectedCurrencyCode } = consoleSettings;
            const viewsSettings = DatagraphHelper.getSettingsObject(consoleSettings, null, SymbolInfo.SymTypeEnum)
            const isIntradayDataSource = action.pricePanelData.HsfData.isIntradayDataSource;
            const latestNodeDate = action.pricePanelData.HsfData.HSFResults[0] ? DateHelper.parseJsonDate(DateHelper.getPSTFromLong(action.pricePanelData.HsfData.HSFResults[0].Date, SymbolInfo, state.isIntraday, isIntradayDataSource), SymbolInfo.MomentZoneID) : new Date();
            let factorsEndDate = latestNodeDate;
            const isCommonStock = DatagraphHelper.isCommonStock(SymbolInfo);
            if (!state.isIntraday && action.pricePanelData && action.pricePanelData.volumePriceData && action.pricePanelData.volumePriceData.adjustTargetDate !== null) {
                const dateText = action.pricePanelData.volumePriceData.adjustTargetDate.split("-");
                factorsEndDate = new Date(dateText[0], dateText[1] - 1, dateText[2]);
            }
            return {
                ...state,
                pricePanelData: action.pricePanelData,
                headerData: action.headerData,
                SymbolInfo,
                Symbol: SymbolInfo.Symbol,
                CompanyName: SymbolInfo.CompanyName,
                SymTypeEnum: SymbolInfo.SymTypeEnum,
                SymbolType: DatagraphHelper.getSymbolType(SymbolInfo.SymTypeEnum),
                isTrading: WorkSpaceRealTimePrices.TradingHours({ SymbolInfo, TradeOpen, TradeClose }),
                notDelayed,
                nodeCount,
                startXPoint,
                viewsSettings,
                priceDataAvailable: length > 1,
                pricePercentChangeStr: length < 1 ? "" : state.pricePercentChangeStr,
                isPricePanelDataReady: true,
                endDate,
                isHistoric,
                selectedCountry: isCurrencyConversionChecked ? selectedCurrencyCode : null,
                periodicityValue: periodicityHelper.getPeriodicityValue(state.majorPeriodicity),
                factorsEndDate,
                latestNodeDate,
                blockOutDates: action.pricePanelData.blackOutDatesDict.BlackOutDates.map((item) => ({ "Date" : DateHelper.getPSTFromLong(item) })),
                isCommonStock
            }
        }
        case ActionTypes.STORE_PADDING_VALUE:
            return {
                ...state,
                padding: action.padding,
                nodeCount: action.nodeCount,
                startXPoint: action.startXPoint,
            }
        case PriceChartConstants.ActionTypes.UPDATE_CHART_ON_STREAMING:
            return {
                ...state,
                IsNASDAQBasic: action.DdeStatusType === DdeStatusType.STREAMING,
            }
        case ActionTypes.UPDATE_DATAGRAPH_TAB_HEIGHT: {
            let indicatorsHeight = 80;
            if (state.SymbolType === SmartViewType.FUND && action.dataGraphViewHeight !== 0) {
                indicatorsHeight = parseInt(action.dataGraphViewHeight / state.availableComponents)
            }
            return {
                ...state,
                indicatorsHeight,
                dataGraphViewHeight: action.dataGraphViewHeight,
            }
        }
        case ActionTypes.UPDATE_AVAILABLE_COMPONENT: {
            let indicatorsHeight = 80;
            if (state.SymbolType === SmartViewType.FUND && state.dataGraphViewHeight !== 0) {
                indicatorsHeight = parseInt(state.dataGraphViewHeight / action.availableComponents)
            }
            return {
                ...state,
                availableComponents: action.availableComponents,
                indicatorsHeight
            }
        }
        case PriceChartConstants.ActionTypes.POINTER_DATA_READY:
            return {
                ...state,
                CPrice: action.CPrice,
                currPrice: action.currPrice,
                currencyStr: action.currencyStr,
                yValue: action.yValue,
                priceChangeStr: action.priceChangeStr,
                pricePercentChange: action.pricePercentChange,
                priceChange: action.priceChange,
                pricePercentChangeStr: action.pricePercentChangeStr,
            }
        case ActionTypes.UPDATE_SYMBOL_ENTRY_LOADING:
            return {
                ...state,
                disablePeriodicityButton: action.disablePeriodicityButton,
                isLoading: action.isLoading,
            }
        case ActionTypes.INIT_PERIODICITY_BUTTON:
        case ActionTypes.INIT_SETTINGS_TO_REDUCER: {
            const tabDataGraphSettings = action.userSettings.NavDatagraphSettings.TabDataGraphSettings
            const preferenceSettings = action.userSettings.NavDatagraphSettings.PreferenceSettings;
            const periodicity = tabDataGraphSettings.Periodicity;
            const buttonSource = tabDataGraphSettings.PeriodicityOptions
            const majorPeriodicity = periodicityHelper.mapMajorPeriodicities(periodicity);
            const graphType = periodicityHelper.getGraphTypeFromPeriodicity(periodicity);
            const periodicityType = periodicityHelper.convertToPeriodicity(periodicity)
            const revWonOnly = preferenceSettings.ReportedEarningsSettings.RevWonOnly;
            const wonOnly = preferenceSettings.ReportedEarningsSettings.WonOnly;
            const selectQuoteServiceOption = preferenceSettings.QuoteServiceSettings.SelectQuoteServiceOption;
            const IsNASDAQBasic = selectQuoteServiceOption && selectQuoteServiceOption === IntradaySourceType.IntradaySource_NASDAQ ? true : false;
            const isMiniListOpen = action?.userSettings?.NavDatagraphSettings?.isMiniListOpen
            if (Object.keys(buttonSource).reduce((previousValue, currentValue) => previousValue && buttonSource[currentValue] !== periodicity, true)) {
                buttonSource.Button3 = periodicity;
                tabDataGraphSettings.SelectedPeriodicityButton = 'Button3';
            }
            else if(buttonSource[tabDataGraphSettings.SelectedPeriodicityButton] !== periodicity){
                Object.entries(buttonSource).forEach(([key, value])=>{
                    if(periodicity === value){
                        tabDataGraphSettings.SelectedPeriodicityButton = key;
                    }
                })
            }
            return {
                ...state,
                periodicity,
                nodeWidth: tabDataGraphSettings.NodeWidth,
                printSettings: action.userSettings.printSettings,
                majorPeriodicity,
                isIntraday: GraphType.Intraday === majorPeriodicity,
                leftScaleWidth: tabDataGraphSettings.LeftScaleWidth,
                rightScaleWidth: tabDataGraphSettings.RightScaleWidth,
                videoMode: tabDataGraphSettings.videoMode,
                useCanvas: tabDataGraphSettings.useCanvas,
                buttonSource,
                IsNASDAQBasic,
                graphType,
                periodicityType,
                revWonOnly,
                wonOnly,
                periodicityButtons: Object.keys(buttonSource).map((buttonKey) => ({
                    Header: PeriodicityTranslateHelper[buttonSource[buttonKey]],
                    IsSelected: buttonKey === tabDataGraphSettings.SelectedPeriodicityButton,
                    Periodicity: buttonSource[buttonKey],
                    key: buttonKey,
                    options: Object.keys(PeriodicityTranslateHelper).map((key) => ({ header: PeriodicityTranslateHelper[key], key, isActive: key === buttonSource[buttonKey] }))
                })),
                isMiniListOpen: isMiniListOpen
            }
        }
        case ActionTypes.PERIODICITY_CHANGE: {
            state.periodicityButtons.forEach((button) => {
                button.IsSelected = false;
            });
            action.button.Periodicity = action.item.key;
            action.button.options.forEach((item) => { item.isActive = item.header === action.item.header })
            state.buttonSource[action.button.key] = action.item.key
            action.button.Header = action.item.header;
            action.button.IsSelected = true;

            const setting = SettingsStore.getConsoleSettings();
            const tabDataGraphSettings = setting.NavDatagraphSettings.TabDataGraphSettings;
            tabDataGraphSettings.Periodicity = action.item.key
            tabDataGraphSettings.SelectedPeriodicityButton = action.button.key
            TimeTrackingWindow.resetTimeTracker(true);
            TimeTrackingWindow.initTimeTracker(state.SymbolInfo, action.item.key);
            TimeTrackingWindow.beginTimeTracker();
            TimeTrackingWindow.isPeriodicityChange = true;
            state.printSettings.externalScore = new Serializable();
            const majorPeriodicity = periodicityHelper.mapMajorPeriodicities(action.item.key)
            const graphType = periodicityHelper.getGraphTypeFromPeriodicity(action.item.key);
            const periodicityType = periodicityHelper.convertToPeriodicity(action.item.key)
            SettingsStore.saveSettings();
            return {
                ...state,
                isLoading: true,
                disablePeriodicityButton: true,
                periodicity: action.item.key,
                majorPeriodicity,
                isIntraday: GraphType.Intraday === majorPeriodicity,
                graphType,
                periodicityType
            }
        }
        case PriceChartConstants.ActionTypes.UPDATE_IS_PRICE_DATA_READY:
            return {
                ...state,
                isPricePanelDataReady: action.isPriceDataReady
            }
        case PriceChartConstants.ActionTypes.UPDATE_IS_RECEIVED_QUOTE:
            return {
                ...state,
                isReceivedQuote: action.isReceivedQuote
            }
        case ActionTypes.SAVE_PRICEPANEL_DATA_REDRAW_LINE:
            return {
                ...state,
                pricePanelData: action.pricePanelData
            }

        case ActionTypes.SET_BLOCK_MENU_DATA:
        case FinancialBlockConstants.ActionTypes.FINANCIAL_BLOCK_COLLAPSE:
        case OwnerShipBlockConstants.ActionTypes.OWNERSHIP_BLOCK_OPEN_COLLAPSE:
        case VolumeChartConstants.ActionTypes.VOLUME_CHART_COLLAPSE_BLOCK:
        case VolumeChartConstants.ActionTypes.INSIDER_CHART_COLLAPSE_BLOCK: {
            const HasVolume = state.pricePanelData.HsfData.HasVolume;
            const commonStock = DatagraphHelper.isCommonStock(state.SymbolInfo);
            const domesticStock = DatagraphHelper.isDomesticStock(state.SymbolInfo);
            const blockMenu = [];
            const InsiderBlockSettings = state.viewsSettings.InsiderBlockSettings;
            const FinancialBlockSettings = state.viewsSettings.FinancialBlockSettings;
            const VolumeBlockSettings = state.viewsSettings.VolumeBlockSettings;
            const OwnershipBlockSettings = state.viewsSettings.OwnershipBlockSettings;

            if (InsiderBlockSettings && domesticStock) {
                const insiderSetting = InsiderBlockSettings[state.majorPeriodicity];
                if (insiderSetting.IsAvailable) {
                    blockMenu.push({
                        Header: "Insider Transactions",
                        resKey: 'ch_msa_it',
                        ischecked: insiderSetting.IsVisible,
                        isActive: true,
                        blockType: BlockType.Insider,
                        isDisabled: false
                    });
                }
            }
            if (VolumeBlockSettings) {
                const volumeSetting = VolumeBlockSettings[state.majorPeriodicity];
                if (volumeSetting.IsAvailable && HasVolume) {
                    blockMenu.push({
                        Header: "Volume",
                        resKey: 'ch_vol_vss_menu',
                        ischecked: volumeSetting.IsVisible,
                        isActive: true,
                        blockType: BlockType.Volume,
                        isDisabled: false
                    });
                }
            }
            if (FinancialBlockSettings && commonStock) {
                const financialBlockSettings = FinancialBlockSettings[state.majorPeriodicity];
                if (financialBlockSettings.IsAvailable) {
                    blockMenu.push({
                        Header: "Financial Block",
                        resKey: 'fb_fb',
                        ischecked: (financialBlockSettings.IsVisible),
                        isActive: true,
                        blockType: BlockType.Financial,
                        isDisabled: false
                    });
                }
            }
            if (OwnershipBlockSettings) {
                const ownershipBlockSettings = OwnershipBlockSettings[state.majorPeriodicity];
                if (ownershipBlockSettings.IsAvailable) {
                    blockMenu.push({
                        Header: "Ownership Block",
                        resKey: 'fb_ob',
                        ischecked: ownershipBlockSettings.IsVisible,
                        isActive: true,
                        blockType: BlockType.Ownership,
                        isDisabled: false
                    });
                }
            }
            state.blockMenu = blockMenu;
            return {
                ...state,
            }
        }

        case ActionTypes.UPDATE_SHOW_TOOL_TIP:
            return {
                ...state,
                showToolTip: action.showToolTip,
            }
        case ActionTypes.UPDATE_IS_HISTORIC:
            ConsoleStore.UpdateHistoricMode(action.isHistoric);
            return {
                ...state,
                isHistoric: action.isHistoric,
            }
        case ActionTypes.UPDATE_NQBOX_STATE:
            return {
                ...state,
                showNQBox: !state.showNQBox,
            }
        case ActionTypes.TOGGLE_MINI_LIST:
            return {
                ...state,
                isMiniListOpen: action.isMiniListOpen,
            }
        case ActionTypes.UPDATE_COMMON_ABOUT_DAILOG:
            return {
                ...state,
                isCommonAboutDialogOpen: action.isCommonAboutDialogOpen,
                commonAboutDialogTitle: action.commonAboutDialogTitle,
                commonAboutDialogUrl: action.commonAboutDialogUrl,
            }
        default:
            return state;
    }
}
export default GraphDataReducer;